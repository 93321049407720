import { createAction, props } from '@ngrx/store';
import { Session } from '../../interfaces/session.interface';
import { Profile } from '../../interfaces/profile.interface';
import { Country } from '../../interfaces/country.interface';

export const Login = createAction('[Auth] Login', props<{ username: string; password: string }>());
export const LoginSuccess = createAction('[Auth] Login Success', props<{ session: Session }>());
export const LoginFail = createAction('[Auth] Login Fail', props<{ error: Error }>());

export const LoadProfile = createAction('[Auth] Load Profile');
export const LoadProfileSuccess = createAction('[Auth] Load Profile Success', props<{ profile: Profile }>());
export const LoadProfileFail = createAction('[Auth] Load Profile Fail', props<{ error: Error }>());

export const EditProfile = createAction('[Auth] Edit Profile', props<{ changes: Partial<Profile> }>());
export const EditProfileSuccess = createAction('[Auth] Edit Profile Success', props<{ profile: Profile }>());
export const EditProfileFail = createAction('[Auth] Edit Profile Fail', props<{ error: Error }>());

export const Logout = createAction('[Auth] Logout');

export const ChangePassword = createAction('[Auth] Change Password', props<{ password: string }>());
export const ChangePasswordSuccess = createAction('[Auth] Change Password Success', props<{ profile: Profile }>());
export const ChangePasswordFail = createAction('[Auth] Change Password Fail', props<{ error: Error }>());
export const UpdateSession = createAction('[Auth] Update Session', props<{ session: Pick<Session, 'userId' | 'userToken'> }>());

// TODO: if we have more general data, create a new generalData Module
export const LoadCountries = createAction('[Auth] Load Coutries');
export const LoadCountriesSuccess = createAction('[Auth] Load Coutries Success', props<{ countries: Country[] }>());
export const LoadCountriesFail = createAction('[Auth] Load Coutries Fail', props<{ error: Error }>());
