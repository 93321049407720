<section id="sign-in">
  <div class="container">
    <img src="../../../../assets/img/logo@3x.png" />

    <form name="form" (ngSubmit)="f.form.valid && login(loginModel.username, loginModel.password)" #f="ngForm" novalidate>
      <mat-form-field>
        <input
          matInput
          placeholder="Username"
          i18n-placeholder
          name="username"
          [(ngModel)]="loginModel.username"
          #username="ngModel"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <input
          i18n-placeholder
          matInput
          placeholder="Password"
          name="password"
          [type]="passFieldType"
          [(ngModel)]="loginModel.password"
          #password="ngModel"
          required
        />
        <mat-icon matSuffix class="pass-icon" (click)="toggleShowPass()"
          >{{ this.passFieldType === 'password' ? 'visibility_off' : 'visibility' }}</mat-icon
        >
      </mat-form-field>

      <button mat-raised-button color="accent" [disabled]="loading$ | async">
        <span i18n="login page|login label">LOGIN</span>
        <mat-spinner diameter="18" class="spinner" *ngIf="loading$ | async"></mat-spinner>
      </button>
      <div class="errorMsg">{{ (error$ | async)?.message }}</div>
    </form>
  </div>
</section>
