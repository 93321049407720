import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  public get googleMapsApiLoaded$() {
    return this.stripeLoaded$.asObservable();
  }

  private stripe: any;
  private readonly key = environment.googleMapsKey;
  private readonly stripeLoaded$ = new BehaviorSubject<boolean>(false);

  public get instance(): any {
    return this.stripe;
  }

  constructor() {
    this.load().then(() => {
      this.stripeLoaded$.next(true);
    });
  }

  public load(): Promise<void> {
    return new Promise((resolve) => {
      const id = 'googleMaps-lib';

      if (document.getElementById(id)) {
        resolve();
        return;
      }

      const src = 'https://maps.googleapis.com/maps/api/js?key=' + this.key + '&libraries=geometry,places&language=en';
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.id = id;
      script.async = true;
      script.src = src;
      document.getElementsByTagName('head')[0].appendChild(script);

      script.onload = () => {
        this.init();
        resolve();
      };
    });
  }

  public init(): void {
    // this.stripe = Stripe(this.key);
  }
}
