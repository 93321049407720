import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  LOCALE_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { getLocaleDirection, DOCUMENT } from '@angular/common';
import { CustomIconService } from '../../services/custom-icon.service';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { CreateTicketComponent } from '@shared/components/create-ticket/create-ticket.component';
import { NavigationStart, Router } from '@angular/router';
import { CreateTicketService } from '@shared/services/create-ticket.service';
// import { Intercom, IntercomBootInput } from '@supy-io/ngx-intercom';
import { environment } from '@env/environment';
import { Profile } from '@auth/interfaces/profile.interface';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { selectAuthUserProfile } from '@auth/store/selectors/profile.selectors';

declare var lpTag: any;
@Component({
  selector: 'app-app-root',
  templateUrl: './app-root.component.html',
  styleUrls: ['./app-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppRootComponent implements OnInit, OnDestroy {
  @ViewChild('contentSideNav') public contentSideNav!: MatSidenav;
  @ViewChild('sideBarContainer', { read: ViewContainerRef }) sideBarContainerRef!: ViewContainerRef;
  public navMenuExpanded: boolean = true;
  public readonly userPersonalData$: Observable<Profile | null>;

  private readonly dir = getLocaleDirection(this.locale);
  private readonly destroyed$ = new Subject<void>();
  private chat: any;

  constructor(
    private customIconService: CustomIconService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private document: Document,
    private r: Renderer2,
    private createTicketService: CreateTicketService,
    private router: Router,
    // private intercom: Intercom,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
    this.customIconService.registerIcons();
    // TODO: Use this approach to add light/dark theme
    if (this.dir === 'rtl') {
      this.r.addClass(this.document.body, 'rtl');
    }

    this.createTicketService.sideBarComp$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.toggleSideNav();
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      if (this.contentSideNav) {
        this.contentSideNav.close();
      }
    });

    this.userPersonalData$ = this.store.select(selectAuthUserProfile);
  }

  ngOnInit(): void {
    this.userPersonalData$
      .pipe(
        filter((data) => !!data),
        take(1)
      )
      .subscribe((customerData) => {
        if (lpTag && lpTag.sdes) {
          // live person
          lpTag.sdes.push({
            type: 'personal', //MANDATORY
            personal: {
              firstname: customerData?.name, // FIRST NAME
              // lastname: "Doe", // SURNAME
              // age: {
              //   age: 34, // AGE AS INTEGER
              //   year: 1980, // BIRTH YEAR
              //   month : 4, // BIRTH MONTH
              //   day: 15 // BIRTH DAY
              // },
              contacts: [
                {
                  email: customerData?.email, // EMAIL
                  phone: customerData?.phone, // PHONE NUMBER
                  address: {
                    country: customerData?.country, // COUNTRY
                    // region: "Moscow" // REGION
                  },
                },
              ],
              // gender: "MALE", // MALE, FEMALE, OTHER
              language: this.locale, // LANGUAGE
              // company: "company" // VISITOR COMPANY NAME
            },
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onToggleNavMenu(isExpanded: boolean): void {
    this.navMenuExpanded = isExpanded;
  }

  private toggleSideNav(): void {
    const viewContainerRef = this.sideBarContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<CreateTicketComponent>(CreateTicketComponent);
    componentRef.instance.closeSideNav.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.contentSideNav.close();
      //Detect change to fix submit ticket not closing the side nav from first click
      this.cdr.detectChanges();
    });
    this.contentSideNav.open();
  }
}
