import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreateTicketService {
  private orderId!: string;
  private sideBarComp = new Subject<void>();
  get sideBarComp$(): Observable<any> {
    return this.sideBarComp.asObservable();
  }

  constructor(private http: HttpClient) {}

  public loadSideBarComp(orderId?: string) {
    if (orderId) {
      this.orderId = orderId;
    }
    this.sideBarComp.next();
  }

  // TODO: Do we need Store for tickets?. Change to ": Observable<Ticket>"
  public createTicket(ticket: { subject: string; description: string }): Observable<any> {
    let body = ticket;
    if (this.orderId) {
      body = { ...body, ...{ orderId: this.orderId } };
    }
    return this.http.post(`${environment.api}/services/customer/cases`, body);
  }
}
