import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateTicket, GetSupportTickets, ResetTicket } from '@dashboard/support/store/actions/support.actions';
import { selectCreatedTicket, selectSupportError, selectSupportLoading } from '@dashboard/support/store/selectors/support.selectors';
import { Store } from '@ngrx/store';
import { State } from '@popperjs/core';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTicketComponent implements OnInit, OnDestroy {
  @Input() ticketForOrderId = '';
  @Output() closeSideNav = new EventEmitter();
  public ticketForm: UntypedFormGroup = new UntypedFormGroup({
    subject: new UntypedFormControl('', Validators.required),
    description: new UntypedFormControl('', Validators.required),
  });
  public showSuccess = false;
  public errorMsg: string = '';
  private readonly destroyed$ = new Subject<void>();
  public readonly loading$: Observable<boolean | null>;
  public showResult: boolean = false;
  public resultData: any;

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {
    this.loading$ = this.store.select(selectSupportLoading);
  }

  ngOnInit(): void {
    this.store
      .select(selectCreatedTicket)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((ticket) => {
        if (ticket) {
          this.store.dispatch(
            GetSupportTickets({
              filters: {
                offset: 0,
                limit: 30,
                withTotal: true,
              },
            })
          );
          this.closeSidenav();
        }
      });

    this.store
      .select(selectSupportError)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((error) => {
        if (error) {
          this.showResult = true;
          this.resultData = {
            status: error,
            buttons: ['continueBtnTicket'],
            message: $localize`There was an error. Error: ` + error.message,
            type: 'ticket',
          };
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.store.dispatch(ResetTicket());
  }

  public closeSidenav(): void {
    this.closeSideNav.emit();
    this.showResult = false;
  }

  public submitNewTicket(): void {
    this.store.dispatch(CreateTicket({ newTicket: this.ticketForm.value }));
  }
}
