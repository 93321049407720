import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Session } from '../interfaces/session.interface';
import { Profile } from '../interfaces/profile.interface';
import { Country } from '../interfaces/country.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  public login(user: string, password: string): Observable<Session> {
    const url = `${environment.api}/services/user/login`;
    return this.http.post<Session>(url, {
      username: user,
      password: password,
    });
  }

  public getProfile(): Observable<Profile> {
    const url = `${environment.api}/services/customer/profile`;
    return this.http.get<Profile>(url);
  }

  public editProfile(changes: Partial<Profile>): Observable<Profile> {
    const url = `${environment.api}/services/customer/profile`;
    return this.http.put<Profile>(url, changes);
  }

  public changePassword(newPassword: string): Observable<Profile> {
    const password = {
      password: newPassword,
    };
    return this.http.put<Profile>(`${environment.api}/services/customer/profile`, password);
  }

  public getCountries(): Observable<Country[]> {
    const headerList = {
      'Content-Type': 'application/json',
      Accept: 'application/api;version=2',
    };
    const url = `${environment.api}/services/meta/dictionaries?type=countries`;
    return this.http
      .get<{ [key: string]: { [key: string]: Country } }>(url, { headers: new HttpHeaders(headerList) })
      .pipe(map((resp) => Object.values(resp['countries'])));
  }
}
