import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class ClearCacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'GET' && !req.url.includes('googleapis')) {
      const noCacheReq = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'),
      });
      return next.handle(noCacheReq);
    }

    return next.handle(req);
  }
}
