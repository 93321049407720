import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
  Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { State } from '@auth/store';
import { Store } from '@ngrx/store';
import { selectAuthUserToken } from '@auth/store/selectors/session.selectors';

@Injectable({
  providedIn: 'root',
})

export class AuthRequiredGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private store: Store<State>, private router: Router) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }
  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    return this.store.select(selectAuthUserToken).pipe(
      map((token: string) => !!token),
      tap((isAuth: boolean) => !isAuth && this.router.navigateByUrl('/sign-in'))
    );
  }
}
