import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { State } from '@auth/store';
import { selectAuthUserToken } from '@auth/store/selectors/session.selectors';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private token!: string;

  constructor(
    private store: Store<State>,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.store.select(selectAuthUserToken).subscribe((token: string) => (this.token = token));
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.token) {
      let authorizeRequest: HttpRequest<any>;
      // if (!request.headers.has('Accept')) {
      //   authorizeRequest = request.clone({
      //     headers: request.headers.set('Accept-Language', this.locale),
      //   });
      // }
      if (request.url.includes(environment.api) || request.url.includes(environment.exportBulk)) {
        authorizeRequest = request.clone({
          headers: request.headers.set('user-token', this.token).set('Accept-Language', this.locale),
        });
      } else {
        // Fallback
        authorizeRequest = request;
      }
      return next.handle(authorizeRequest);
    }

    return next.handle(request);
  }
}
