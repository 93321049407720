import { createReducer, on } from '@ngrx/store';
import * as SupportActions from '../actions/support.actions';

import { NewTicket, TicketDetails, TicketsData } from '@dashboard/support/interfaces/tickets-interfaces';
import { Articles, FaqArticles } from '@dashboard/support/interfaces/faq-articles-interfaces';
import { Categories, FaqCategories } from '@dashboard/support/interfaces/faq-categories-interfaces';
import { FaqSearchResult } from '@dashboard/support/interfaces/faq-search-result-interfaces';
import { ArticleDetails } from '@dashboard/support/interfaces/faq-articles-detalis';

export interface SupportState {
  ticketsData: TicketsData | null;
  articles: Articles[] | null;
  categories: Categories[] | null;
  searchResult: FaqSearchResult | null;
  articlesDetails: ArticleDetails[] | null;
  totalCount: number;
  loading: boolean;
  error: Error | null;
  ticket: TicketDetails | null;
}

export const initialState: SupportState = {
  ticketsData: null,
  ticket: null,
  searchResult: null,
  articles: null,
  articlesDetails: null,
  categories: null,
  totalCount: 0,
  loading: false,
  error: null,
};

export const SupportReducer = createReducer(
  initialState,
  on(
    SupportActions.GetSupportTickets,
    SupportActions.GetFAQCategories,
    SupportActions.GetFAQCategories,
    SupportActions.GetSearchByCriteria,
    SupportActions.GetFAQArticleById,
    SupportActions.CreateTicket,
    (state) => ({
      ...state,
      // ticketsData: null,
      loading: true,
      error: null,
    })
  ),

  on(SupportActions.ResetTicket, (state) => ({
    ...state,
    ticket: null,
    loading: false,
    error: null,
  })),

  on(SupportActions.GetSupportTicketsSuccess, (state, { ticketsData }) => ({
    ...state,
    ticketsData,
    loading: false,
    error: null,
  })),
  on(SupportActions.GetFAQCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories: categories.categories,
    loading: false,
    error: null,
  })),
  on(SupportActions.GetFAQArticlesSuccess, (state, { articles }) => ({
    ...state,
    articles: articles.articles,
    loading: false,
    error: null,
  })),
  on(SupportActions.GetSearchByCriteriaSuccess, (state, { searchResult }) => ({
    ...state,
    searchResult,
    loading: false,
    error: null,
  })),
  on(SupportActions.GetFAQArticleByIdSuccess, (state, { articlesDetails }) => ({
    ...state,
    articlesDetails: articlesDetails.article.translations,
    loading: false,
    error: null,
  })),

  on(SupportActions.CreateTicketSuccess, (state, { ticket }) => ({
    ...state,
    ticket,
    loading: false,
    error: null,
  })),
  on(
    SupportActions.GetSupportTicketsFail,
    SupportActions.GetFAQArticlesFail,
    SupportActions.GetFAQCategoriesFail,
    SupportActions.GetSearchByCriteriaFail,
    SupportActions.GetFAQArticlesByIdFail,
    SupportActions.CreateTicketFail,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    })
  )
);
