<div id="app-wrapper">
  <app-header (toggleNavMenu)="onToggleNavMenu($event)" [locale]="locale"></app-header>
  <div id="main">
    <div id="nav">
      <app-nav-menu></app-nav-menu>
    </div>
    <div id="content">
      <mat-drawer-container class="content-drawer-container">
        <mat-drawer #contentSideNav mode="side" opened="false" position="end">
          <ng-template #sideBarContainer></ng-template>
        </mat-drawer>
        <mat-drawer-content>
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</div>
