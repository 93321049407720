<header id="main">
  <mat-toolbar class="toolbar">
    <a href="/" class="logo-link">
      <img src="../../../../assets/img/logo-home@2x.png" class="logo" />
    </a>

    <!-- This fills the remaining space of the current row -->
    <div class="remaining-spacer">
      <!-- <app-header-search-bar></app-header-search-bar> -->
    </div>

    <span class="user-name-holder" *ngIf="(profile$ | async)?.name">
      <ng-container i18n="header|Hi [username]">Hi</ng-container>
      <span class="user-name">&nbsp;{{ (profile$ | async)?.name }}</span>
    </span>

    <!-- Acount settings dropdown menu -->
    <button mat-icon-button [matMenuTriggerFor]="accountMenu" id="accountMenuBtn">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #accountMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false" class="accountMenu">
      <i class="material-icons menu-arrow">navigation</i>
      <button mat-menu-item [routerLink]="['./account']">
        <mat-icon>account_circle</mat-icon>
        <span i18n="header|User menu">Profile</span>
      </button>
      <button mat-menu-item [routerLink]="['./settings']">
        <mat-icon>settings</mat-icon>
        <span i18n="header|User menu">Settings</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span i18n="header|User menu">Logout</span>
      </button>
    </mat-menu>

    <a (click)="changeLanguage()" class="lang">
      <img [src]="locale === 'ar' ? '../../../../assets/img/en.svg' : '../../../../assets/img/ar.svg'" />
    </a>
  </mat-toolbar>
</header>
