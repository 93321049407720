<nav id="menu" [class.is-open]="isMenuOpen">
  <button mat-fab color="accent" class="new-action-btn" [matMenuTriggerFor]="newActionMenu">
    <mat-icon>add</mat-icon> <span class="action-text" i18n="site navigation|main menu option">Create new</span>
  </button>
  <mat-menu #newActionMenu="matMenu">
    <button mat-menu-item i18n="site navigation|main menu option" [routerLink]="'orders/create-order'">Create Single Order</button>
    <button mat-menu-item i18n="site navigation|main menu option" [routerLink]="'orders/create-bulk'">Create Bulk Order</button>
    <button
      mat-menu-item
      i18n="site navigation|main menu option"
      [routerLink]="'fulfillment/create-ff-order'"
      *ngIf="fulfillmentEnabled$ | async"
    >
      Create Fulfillment Order
    </button>
    <button
      mat-menu-item
      i18n="site navigation|main menu option"
      [routerLink]="'fulfillment/create-ff-bulk-order'"
      *ngIf="fulfillmentEnabled$ | async"
    >
      Create Bulk Fulfillment Order
    </button>
    <button mat-menu-item i18n="site navigation|main menu option" (click)="navigateToNewAddress()">Create Address</button>
    <button mat-menu-item i18n="site navigation|main menu option" (click)="newTicket()">Create Support Ticket</button>
  </mat-menu>
  <ul class="menu">
    <li>
      <a href="#" routerLinkActive="active" [routerLink]="'/dashboard/home'" [routerLinkActiveOptions]="{ exact: true }">
        <mat-icon svgIcon="home"></mat-icon>
        <span i18n="site navigation|main menu option">Home</span>
      </a>
    </li>
    <li>
      <a
        href="javascript:;"
        [matMenuTriggerFor]="ordersMenu"
        class="has-submenu"
        [class.active]="
          ordersMenuItem1.isActive || ordersMenuItem2.isActive || ordersMenuItem3.isActive || ordersMenuItem4.isActive
        "
      >
        <mat-icon svgIcon="orders"></mat-icon>
        <span i18n="site navigation|main menu option">Orders</span>
      </a>
      <mat-menu #ordersMenu="matMenu" MenuPositionX="after" overlapTrigger="true" class="nav-sub-menu">
        <button mat-menu-item routerLinkActive #ordersMenuItem1="routerLinkActive" [routerLink]="'orders/create-order'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Single Order</span>
        </button>
        <button mat-menu-item routerLinkActive #ordersMenuItem2="routerLinkActive" [routerLink]="'orders/create-bulk'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Bulk Order</span>
        </button>
        <button mat-menu-item routerLinkActive #ordersMenuItem3="routerLinkActive" [routerLink]="'orders/live-orders'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Live Orders</span>
        </button>
        <button mat-menu-item routerLinkActive #ordersMenuItem4="routerLinkActive" [routerLink]="'orders/order-history'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Orders History</span>
        </button>
        <button mat-menu-item routerLinkActive #ordersMenuItem4="routerLinkActive" [routerLink]="'orders/awb-search'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">AWB Search</span>
        </button>
      </mat-menu>
    </li>
    <li *ngIf="fulfillmentEnabled$ | async">
      <a
        href="javascript:;"
        [matMenuTriggerFor]="ffmtMenu"
        class="has-submenu"
        [class.active]="ffmtMenuItem1.isActive || ffmtMenuItem2.isActive || ffmtMenuItem3.isActive"
      >
        <mat-icon svgIcon="fulfillment"></mat-icon>
        <span i18n="site navigation|main menu option">Fulfillment</span>
      </a>
      <mat-menu #ffmtMenu="matMenu" MenuPositionX="after" overlapTrigger="true" class="nav-sub-menu">
        <button mat-menu-item routerLinkActive #ffmtMenuItem1="routerLinkActive" [routerLink]="'fulfillment/create-ff-order'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Single Order</span>
        </button>
        <button mat-menu-item routerLinkActive #ffmtMenuItem2="routerLinkActive" [routerLink]="'fulfillment/create-ff-bulk-order'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Bulk Order</span>
        </button>
        <button mat-menu-item routerLinkActive #ffmtMenuItem3="routerLinkActive" [routerLink]="'fulfillment/inventory'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Stock/inventory</span>
        </button>
        <button mat-menu-item routerLinkActive #ffmtMenuItem4="routerLinkActive" [routerLink]="'fulfillment/ff-history'">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Fulfillment Orders</span>
        </button>
        <button mat-menu-item routerLinkActive #ffmtMenuItem5="routerLinkActive" [routerLink]="'fulfillment/ff-purchase-orders'">
          <mat-icon>remove</mat-icon>
          <span i18n="@@purchaseOrders">Purchase Orders</span>
        </button>
      </mat-menu>
    </li>
    <li>
      <a href="javascript:;" [routerLink]="'tracking'" routerLinkActive="active">
        <mat-icon svgIcon="track"></mat-icon>
        <span i18n="site navigation|main menu option">Tracking</span>
      </a>
    </li>
    <li>
      <a href="javascript:;" [routerLink]="'manifests'" routerLinkActive="active">
        <mat-icon svgIcon="manifest"></mat-icon>
        <span i18n="site navigation|main menu option">Manifests</span>
      </a>
    </li>
    <li>
      <a href="javascript:;" [routerLink]="'address-book'" routerLinkActive="active">
        <mat-icon svgIcon="contacts"></mat-icon>
        <span i18n="site navigation|main menu option">Address Book</span>
      </a>
    </li>
    <li>
      <a href="javascript:;" [routerLink]="'statistics'" routerLinkActive="active">
        <mat-icon svgIcon="reports"></mat-icon>
        <span i18n="site navigation|main menu option">Statistics</span>
      </a>
    </li>
    <li>
      <!-- <a
        href="#"
        onclick="return false;"
        [matMenuTriggerFor]="supportMenu"
        class="has-submenu"
        [class.active]="supportMenuItem1.isActive || supportMenuItem2.isActive"
      > -->
      <a href="#" onclick="return false;" [matMenuTriggerFor]="supportMenu" class="has-submenu" [class.active]="supportMenuItem1.isActive">
        <mat-icon svgIcon="livehelp"></mat-icon>
        <span i18n="site navigation|main menu option">Support</span>
      </a>
      <mat-menu #supportMenu="matMenu" MenuPositionX="after" overlapTrigger="true" class="nav-sub-menu">
        <button mat-menu-item [routerLink]="'support'" routerLinkActive #supportMenuItem1="routerLinkActive">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">Tickets</span>
        </button>
        <!-- <button mat-menu-item [routerLink]="'support/faq'" routerLinkActive #supportMenuItem2="routerLinkActive">
          <mat-icon>remove</mat-icon>
          <span i18n="site navigation|main menu option">FAQs</span>
        </button> -->
      </mat-menu>
    </li>
  </ul>

  <button class="menu-toggle" (click)="toggleMenu()" [class.open]="isMenuOpen">
    <mat-icon>chevron_right</mat-icon>
  </button>
</nav>
