import { ArticlesDetails } from '@dashboard/support/interfaces/faq-articles-detalis';
import { FaqArticles } from '@dashboard/support/interfaces/faq-articles-interfaces';
import { FaqCategories } from '@dashboard/support/interfaces/faq-categories-interfaces';
import { FaqSearchResult } from '@dashboard/support/interfaces/faq-search-result-interfaces';
import { createAction, props } from '@ngrx/store';

import { TicketPagination, TicketsData, NewTicket, TicketDetails } from '../../interfaces/tickets-interfaces';

export const CreateTicket = createAction('[Support] Create Ticket', props<{ newTicket: NewTicket }>());
export const CreateTicketSuccess = createAction('[Support] Create Ticket Success', props<{ ticket: TicketDetails }>());
export const CreateTicketFail = createAction('[Support] Create Ticket Fail', props<{ error: Error }>());

export const ResetTicket = createAction('[Support] Reset Create Ticket');

export const GetSupportTickets = createAction('[Support] Get Support Tickets', props<{ filters: TicketPagination }>());
export const GetSupportTicketsSuccess = createAction('[Support] Get Support Tickets Success', props<{ ticketsData: TicketsData }>());
export const GetSupportTicketsFail = createAction('[Support] Get Support Tickets Fail', props<{ error: Error }>());

export const GetFAQCategories = createAction('[Support] Get FAQ Categories');
export const GetFAQCategoriesSuccess = createAction('[Support]Get FAQ Categories Success', props<{ categories: FaqCategories }>());
export const GetFAQCategoriesFail = createAction('[Support] Get FAQ Categories Fail', props<{ error: Error }>());

export const GetFAQArticles = createAction('[Support] Get FAQ Articles');
export const GetFAQArticlesSuccess = createAction('[Support]Get FAQ Articles Success', props<{ articles: FaqArticles }>());
export const GetFAQArticlesFail = createAction('[Support] Get FAQ Articles Fail', props<{ error: Error }>());

export const GetFAQArticleById = createAction('[Support] Get FAQ Articles By ID', props<{ typeItemId: number }>());
export const GetFAQArticleByIdSuccess = createAction(
  '[Support]Get FAQ Articles By ID Success',
  props<{ articlesDetails: ArticlesDetails }>()
);
export const GetFAQArticlesByIdFail = createAction('[Support] Get FAQ Articles By ID Fail', props<{ error: Error }>());

export const GetSearchByCriteria = createAction('[Support] Get FAQ Search By Criteria', props<{ queryTxt: string }>());
export const GetSearchByCriteriaSuccess = createAction(
  '[Support]Get FAQ Search By Criteria Success',
  props<{ searchResult: FaqSearchResult }>()
);
export const GetSearchByCriteriaFail = createAction('[Support] Get Search By Criteria Fail', props<{ error: Error }>());
