import { selectSessionState } from '../index';
import { SessionState } from '../reducer/session.reducer';
import { createSelector } from '@ngrx/store';
import { Roles } from '@core/enums/roles.enum';

export const selectAuthUserUid = createSelector(selectSessionState, (state: SessionState) => state.userId);
export const selectAuthUserToken = createSelector(selectSessionState, (state: SessionState) => state && state.userToken);
export const selectIsAuthenticated = createSelector(selectAuthUserToken, (userToken: string) => {
  return !!userToken;
});
export const selectIsCustomer = createSelector(
  selectSessionState,
  (state: SessionState) => state.userRoles && state.userRoles.indexOf(Roles.CUSTOMER) > -1
);
