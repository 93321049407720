import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Logout } from '../../auth/store/actions/auth.actions';
import { State } from '../../auth/store';
import { Store } from '@ngrx/store';
// import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor(private store: Store<State>) {}

  handleError(
    // response: HttpErrorResponse | HttpResponse<any>,
    response: HttpErrorResponse,
    url: string
  ): Observable<HttpEvent<any>> {
    switch (response.status) {
      case 0:
        const resp = response.error;
        return throwError(
          () =>
            new Error({
              ...resp,
              message: 'No internet connection',
            })
        );

      case 302: // handle session timeout
        this.store.dispatch(Logout());
        // return throwError(() => new Error(response.error));
        return throwError(() => response.error);

      case 401:
        this.store.dispatch(Logout());
        return throwError(() => response.error);

      case 403:
        this.store.dispatch(Logout());
        return throwError(() => response.error);

      default:
        return throwError(() => response.error);
    }
  }
}
