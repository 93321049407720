import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { selectFulfillmentEnabled } from '@auth/store/selectors/profile.selectors';
import { Observable } from 'rxjs';
import { CreateTicketService } from '@shared/services/create-ticket.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './app-nav-menu.component.html',
  styleUrls: ['./app-nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavMenuComponent {
  public readonly fulfillmentEnabled$: Observable<boolean | undefined>;
  public isMenuOpen = true;

  constructor(
    private router: Router,
    private store: Store<State>,
    private createTicketService: CreateTicketService
  ) {
    this.fulfillmentEnabled$ = this.store.select(selectFulfillmentEnabled);
  }

  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  /**
   * Avoid link to be clicked. Added to have routerLinkActive working on parent options
   */
  public handleClick(event: Event): false {
    event.stopImmediatePropagation();
    event.preventDefault();
    return false;
  }

  public navigateToNewAddress(): void {
    this.router.navigate(['/dashboard/address-book'], {
      queryParams: { newAddress: true },
    });
  }

  public newTicket(): void {
    this.createTicketService.loadSideBarComp();
  }
}
