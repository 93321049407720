<div class="success-wrapper">
  <div class="success-body-wrapper">
    <div class="dialog-data-container" *ngIf="data">
      <div *ngIf="data.status === 'success'">
        <mat-icon class="success-icon">check_circle</mat-icon>
        <div class="success-message" i18n="Shared|result">Success!</div>
      </div>

      <div *ngIf="data.status === 'error'">
        <mat-icon class="error-icon">error_outline</mat-icon>
        <div class="error-message" i18n="Shared|result">Error!</div>
      </div>

      <div class="success-description">{{ data.message }}</div>

      <div class="order-num order-id" *ngIf="data.orderNumber">
        <span i18n="Shared|result type" *ngIf="data.type === 'bulk'">Manifest ID</span>
        <span i18n="Shared|result type" *ngIf="data.type !== 'bulk'">Order ID</span>
        {{ data.orderNumber }}
      </div>

      <div class="bulk-upload-stats" *ngIf="data.bulkOrdersDetails">
        <div class="row">
          <div class="col-label" i18n="Shared|result">Processed</div>
          <div class="col-result">{{ data.bulkOrdersDetails.total }}</div>
        </div>
        <div class="row success">
          <div class="col-label" i18n="Shared|result">Success</div>
          <div class="col-result">{{ data.bulkOrdersDetails.succeeded }}</div>
        </div>
        <div class="row error">
          <div class="col-label" i18n="Shared|result">Error</div>
          <div class="col-result">
            {{ data.bulkOrdersDetails.failed }}

            <div *ngIf="data.bulkOrdersDetails.failed > 0" class="export">
              <button mat-button [matMenuTriggerFor]="menu" *ngIf="data.bulkOrdersDetails.failed">
                <mat-icon svgIcon="download"></mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="exportFailedOrders('csv')">
                  <span i18n="Shared|result">CSV File</span>
                </button>
                <button mat-menu-item (click)="exportFailedOrders('xlsx')">
                  <span i18n="Shared|result">EXCEL File</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="button-wrapper">
    <ng-container *ngFor="let button of buttonsArray">
      <button
        class="success-button"
        [ngClass]="{ 'white-button': button.white }"
        mat-raised-button
        color="accent"
        (click)="doClick(button.clickFn)"
      >
        {{ button.label }}
      </button>
    </ng-container>
  </div>
</div>
