<div id="create-case" class="section-container">
  <header class="header">
    <h3 i18n="Ticket|Create ticket">Create ticket</h3>
    <mat-icon class="close" (click)="closeSidenav()">close</mat-icon>
  </header>

  <div *ngIf="loading$ | async; else content" class="loading">
    <mat-spinner color="accent"></mat-spinner>
  </div>

  <ng-template #content>
    <div *ngIf="!showResult">
      <form [formGroup]="ticketForm" id="create-case-form" [ngClass]="{ completed: showSuccess }">
        <mat-form-field class="w-100" appearance="outline" [floatLabel]="'auto'">
          <input
            matInput
            i18n-placeholder
            placeholder="What is the ticket about"
            style="font-size: 16px"
            formControlName="subject"
            maxlength="254"
            required
          />
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline" [floatLabel]="'auto'">
          <textarea
            i18n-placeholder
            matInput
            #input
            placeholder="Could you elaborate on that?"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            style="font-size: 16px"
            cdkAutosizeMinRows="3"
            formControlName="description"
            required
          ></textarea>
          <mat-hint align="end">{{ 500 - input.value.length || 0 }}</mat-hint>
        </mat-form-field>

        <button
          class="next-step-button"
          mat-raised-button
          color="accent"
          [disabled]="ticketForm.invalid"
          (click)="submitNewTicket()"
          i18n="Ticket|Create ticket"
        >
          SUBMIT
        </button>
        <div class="errorMsg" *ngIf="errorMsg">{{ errorMsg }}</div>
      </form>
    </div>
    <app-result *ngIf="showResult" [data]="resultData" (closeSideNav)="closeSidenav()" class="success"></app-result>
  </ng-template>
</div>
