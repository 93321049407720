import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { Country } from '../../interfaces/country.interface';

export interface CountriesState {
  countries: Country[];
  loaded: boolean;
  error: Error | null;
}

export const initialState: CountriesState = {
  countries: [],
  loaded: false,
  error: null,
};

export const countriesReducer = createReducer(
  initialState,
  on(AuthActions.LoadCountries, (state) => ({ ...state, error: null })),
  on(AuthActions.LoadCountriesSuccess, (state, { countries }) => ({ ...state, countries, loaded: true, error: null })),
  on(AuthActions.LoadCountriesFail, (state, { error }) => ({ ...state, countries: [], loaded: false, error }))
);
