import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { Profile } from '@auth/interfaces/profile.interface';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { selectAuthUserProfile } from '@auth/store/selectors/profile.selectors';
import { Logout } from '@auth/store/actions/auth.actions';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
  @Input() locale: string = '';
  @Output() toggleNavMenu = new EventEmitter<boolean>();
  public readonly profile$: Observable<Profile | null>;
  private isExpanded = true;

  constructor(private store: Store<State>) {
    this.profile$ = this.store.select(selectAuthUserProfile);
  }

  public logout() {
    this.store.dispatch(Logout());
  }

  public changeLanguage() {
    window.location.href = this.locale === 'ar' ? '/' : '/ar/';
  }
}
