import { selectCountriesState } from '../index';
import { CountriesState } from '../reducer/countries.reducer';
import { createSelector } from '@ngrx/store';
import { Country } from '@auth/interfaces/country.interface';
import { selectProfileCountry } from './profile.selectors';

export const selectCountries = createSelector(selectCountriesState, (state: CountriesState) => state.countries);
export const selectCountriesLoaded = createSelector(selectCountriesState, (state: CountriesState) => state.loaded);
export const selectCountriesError = createSelector(selectCountriesState, (state: CountriesState) => state.error);

export const selectCountriesNames = createSelector(selectCountries, (countries: Country[]) => {
  return countries.map((country) => country.name).sort();
});

export const selectCountriesCodes = createSelector(selectCountries, (countries: Country[]) => {
  return countries.map((country) => country.isoAlpha3).sort();
});

export const selectCountriesCities = createSelector(selectCountries, (countries: Country[]) => {
  let countriesCities: { [key: string]: string[] } = {};
  countries.forEach((country: Country) => {
    countriesCities[country.name] = [];
    country.cities.forEach((city) => {
      countriesCities[country.name].push(city);
    });
  });
  return countriesCities;
});

export const selectCountryCities = (country: string) =>
  createSelector(selectCountries, (countries) => {
    return countries.find((co) => co.isoAlpha3 === country)?.cities;
  });

export const selectCountryCitiesByName = (countryName: string) =>
  createSelector(selectCountries, (countries) => {
    const cities = countries.find((country) => country.name === countryName)?.cities;
    return cities ? [...cities].sort() : null;
  });

export const selectCountryCitiesByCode = (countryCode: string) =>
  createSelector(selectCountries, (countries) => {
    const cities = countries.find((country) => country.isoAlpha3 === countryCode)?.cities;
    return cities ? [...cities].sort() : null;
  });

export const selectCountryAddressCodeTypes = (country: string) =>
  createSelector(selectCountries, (countries) => {
    return countries.find((co) => co.isoAlpha3 === country)?.addressCodeTypes;
  });

export const selectCountryCenter = createSelector(selectCountries, selectProfileCountry, (countries, country) => {
  return countries.find((co) => co.isoAlpha3 === country)?.center;
});

export const countryisoAlpha2 = (country: string) =>
  createSelector(selectCountries, (countries) => {
    return countries.find((co) => co.isoAlpha3 === country)?.isoAlpha2;
  });

export const selectCountryDialCode = (country: string) =>
  createSelector(selectCountries, (countries) => {
    return countries.find((co) => co.isoAlpha3 === country)?.dialCode;
  });

export const selectCountryPhoneRules = (country: string) =>
  createSelector(selectCountries, (countries) => {
    return countries.find((co) => co.isoAlpha3 === country)?.phoneRules;
  });
