import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {
  private readonly svgIcons: { [key: string]: string }[] = [
    { account: './assets/icons/account.svg' },
    { bike: './assets/icons/bike.svg' },
    { calendar: './assets/icons/calendar.svg' },
    { car: './assets/icons/car.svg' },
    { contacts: './assets/icons/contacts.svg' },
    { delete: './assets/icons/delete.svg' },
    { download: './assets/icons/download.svg' },
    { delete: './assets/icons/delete.svg' },
    { edit: './assets/icons/edit.svg' },
    { fragile: './assets/icons/fragile.svg' },
    { home: './assets/icons/home.svg' },
    { list: './assets/icons/list.svg' },
    { livehelp: './assets/icons/livehelp.svg' },
    { manifest: './assets/icons/manifest.svg' },
    { money: './assets/icons/money.svg' },
    { note: './assets/icons/note.svg' },
    { orders: './assets/icons/orders.svg' },
    { pack: './assets/icons/pack.svg' },
    { people: './assets/icons/people.svg' },
    { pin: './assets/icons/pin.svg' },
    { print: './assets/icons/print.svg' },
    { reports: './assets/icons/reports.svg' },
    { snow: './assets/icons/snow.svg' },
    { track: './assets/icons/track.svg' },
    { truck: './assets/icons/truck.svg' },
    { confirmedbycust: './assets/icons/confirmedbycust.svg' },
    { enroute: './assets/icons/enroute.svg' },
    { pickup: './assets/icons/pickup.svg' },
    { drivercall: './assets/icons/drivercall.svg' },
    { cscall: './assets/icons/cscall.svg' },
    { dropoffcomplete: './assets/icons/dropoffcomplete.svg' },
    { refresh: './assets/icons/refresh.svg' },
    { settings: './assets/icons/settings.svg' },
    { fulfillment: './assets/icons/fulfillment.svg' },
    { 'ff-order': './assets/icons/ff-order.svg' },
    { 'icon-error': './assets/icons/icon-error.svg' },
    { 'icon-info': './assets/icons/icon-info.svg' },
    { 'icon-close': './assets/icons/icon-close.svg' },
    { 'icon-success': './assets/icons/icon-success.svg' },
    { 'icon-warning': './assets/icons/icon-warning.svg' },
    { 'map-pin': './assets/icons/map-pin.svg' },
    { cart: './assets/icons/cart.svg' },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public registerIcons(): void {
    for (let i = 0, len = this.svgIcons.length; i < len; i++) {
      const url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.svgIcons[i][Object.keys(this.svgIcons[i])[0]]);
      this.matIconRegistry.addSvgIcon(Object.keys(this.svgIcons[i])[0], url);
    }
  }
}
