import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log("this.localeOrderResponse", this.locale);
    if (this.locale) {
      let authorizeRequest: HttpRequest<any>;
      if (!request.headers.has('Accept')) {
        authorizeRequest = request.clone({
          headers: request.headers.set('Accept-Language', this.locale),
        });
      } else {
        // Fallback
        authorizeRequest = request;
      }
      return next.handle(authorizeRequest);
    }

    return next.handle(request);
  }
}
