import { State as RootState } from '@core/store/';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { SupportState, SupportReducer } from './reducers/support.reducer';

export interface State extends RootState {
  support: supportState;
}

export interface supportState {
  support: SupportState;
}

export const reducers: ActionReducerMap<supportState> = {
  support: SupportReducer,
};

export const selectAppDataState = createFeatureSelector<supportState>('support');

export const selectSupportState = createSelector(selectAppDataState, (state: supportState) => state && state.support);
