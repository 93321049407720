import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AuthModule } from '@auth/auth.module';
import { CoreModule } from '@core/core.module';
import { DatePipe } from '@angular/common';
// import { IntercomModule } from '@supy-io/ngx-intercom';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot(),
    CoreModule.forRoot(),
    // IntercomModule.forRoot({
    //   appId: environment.chat.appId, // from your Intercom config
    //   updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    // }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
