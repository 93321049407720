import { selectSupportState } from '../index';
import { createSelector } from '@ngrx/store';
import { SupportState } from '../reducers/support.reducer';

export const selectSupportTickets = createSelector(selectSupportState, (state: SupportState) => state && state.ticketsData);

export const selectCreatedTicket = createSelector(selectSupportState, (state: SupportState) => state && state.ticket);

export const selectSupportLoading = createSelector(selectSupportState, (state: SupportState) => state && state.loading);
export const selectSupportError = createSelector(selectSupportState, (state: SupportState) => state && state.error);

export const selectFAQCategories = createSelector(selectSupportState, (state: SupportState) => state && state.categories);

export const selectFAQArticles = createSelector(selectSupportState, (state: SupportState) => state && state.articles);

export const selectFAQArticlesByCategoryId = (id: number) =>
  createSelector(selectFAQArticles, (articles) => {
    if (id) {
      return articles?.filter((o) => o.category_id === id);
    }
    return articles || null;
  });

export const selectFAQArticlesDetails = createSelector(selectSupportState, (state: SupportState) => state && state.articlesDetails);

export const selectFAQSearchResult = createSelector(selectSupportState, (state: SupportState) => state && state.searchResult);
