import { Profile } from '@auth/interfaces/profile.interface';
import { selectAuthProfileState } from '../index';
import { ProfileState } from '../reducer/profile.reducer';
import { createSelector } from '@ngrx/store';

export const selectAuthUserProfile = createSelector(selectAuthProfileState, (state: ProfileState) => state.userProfile);
export const selectAuthProfileLoading = createSelector(selectAuthProfileState, (state: ProfileState) => state.loading);
export const selectAuthProfileError = createSelector(selectAuthProfileState, (state: ProfileState) => state.error);
export const selectProfileCountry = createSelector(
  selectAuthProfileState,
  (state: ProfileState) => state.userProfile && state.userProfile.country
);

export const selectNormalOrderAmount = createSelector(selectAuthUserProfile, (userProfile) => userProfile?.normalOrderAmount);
export const selectCurrency = createSelector(selectAuthUserProfile, (userProfile) => userProfile?.subsidiary.currency);

export const selectFulfillmentEnabled = createSelector(selectAuthUserProfile, (userProfile) => userProfile?.fulfillmentEnabled);

export const selectAWBType = createSelector(selectAuthUserProfile, (userProfile) => userProfile?.awbType);
export const selectAWBCopies = createSelector(selectAuthUserProfile, (userProfile) => userProfile?.awbCopies);

export const selectUserPersonalData = createSelector(selectAuthUserProfile, (userProfile) => {
  if (userProfile) {
    const { name, email, phone } = userProfile;
    return { name, email, phone };
  }
  return null;
});

export const selectSingleOrderCategories = createSelector(
  selectAuthProfileState,
  (state: ProfileState) => state.userProfile && state.userProfile.domesticOrderCategories
);

export const selectAuthProfileUiSettings = createSelector(
  selectAuthUserProfile,
  (profile: Profile | null) => profile && profile.uiSettings
);

// get UI settings from key name
export const selectAuthProfileUiSettingsByName = (key: string) =>
  createSelector(selectAuthProfileUiSettings, (settings) => settings && settings[key]);
