import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpSpinnerInterceptor implements HttpInterceptor {
  private readonly requests: HttpRequest<any>[] = [];

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      finalize(() => this.onRequestEnd(req))
    );
  }

  private onRequestEnd(req: HttpRequest<any>): void {
    const i = this.requests.indexOf(req);

    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    if (!this.requests.length) {
    }
  }
}
