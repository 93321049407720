import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, map, mergeMap, catchError, exhaustMap, tap, withLatestFrom, switchMap, filter } from 'rxjs';
import { AuthApiService } from '../../services/auth-api.service';
import {
  Login,
  LoginSuccess,
  LoginFail,
  LoadProfile,
  LoadProfileSuccess,
  LoadProfileFail,
  EditProfile,
  LoadCountries,
  LoadCountriesSuccess,
  LoadCountriesFail,
  Logout,
  EditProfileSuccess,
  EditProfileFail,
  ChangePassword,
  ChangePasswordSuccess,
  ChangePasswordFail,
} from '../actions/auth.actions';
import { Router } from '@angular/router';
import { Session } from '../../interfaces/session.interface';
import { Profile } from '../../interfaces/profile.interface';
import { Country } from '../../interfaces/country.interface';
import { State } from '../index';
import { Store } from '@ngrx/store';
import { selectCountriesLoaded } from '../selectors/countries.selectors';
import Backendless from 'backendless';
import { RealTimeService } from '@core/services/real-time.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Login),
      exhaustMap((action) =>
        this.authApiService.login(action.username, action.password).pipe(
          mergeMap((session: Session) => {
            Backendless.LocalCache.set('user-token', session.userToken);
            return [LoginSuccess({ session }), LoadProfile(), LoadCountries()];
          }),
          catchError((error) => of(LoginFail({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginSuccess),
        tap(() => {
          this.realTimeService.initRealTime();
          this.router.navigateByUrl('/dashboard');
        })
      ),
    { dispatch: false }
  );

  loadProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadProfile),
      exhaustMap((action) => this.authApiService.getProfile().pipe(map((profile: Profile) => LoadProfileSuccess({ profile })))),
      catchError((error) => {
        return of(LoadProfileFail({ error }));
      })
    )
  );

  editProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditProfile),
      exhaustMap((action) =>
        this.authApiService.editProfile(action.changes).pipe(map((profile: Profile) => EditProfileSuccess({ profile })))
      ),
      catchError((error) => {
        return of(EditProfileFail({ error }));
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Logout),
        tap(() => {
          Backendless.LocalCache.remove('user-token');
          this.realTimeService.stopRealTime();
          this.router.navigateByUrl('/sign-in');
        })
      ),
    { dispatch: false }
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangePassword),
      exhaustMap((action) =>
        this.authApiService.changePassword(action.password).pipe(
          map((profile: Profile) => {
            // Backendless.LocalCache.set('user-token', session.userToken);
            return ChangePasswordSuccess({ profile });
          }),
          catchError((error) => of(ChangePasswordFail({ error })))
        )
      )
    )
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadCountries),
      withLatestFrom(this.store.select(selectCountriesLoaded)),
      filter(([action, hasLoaded]) => !hasLoaded), // only continue if hasLoaded is false
      switchMap((action) => {
        return this.authApiService.getCountries().pipe(
          map((countries: Country[]) => {
            return LoadCountriesSuccess({ countries });
          }),
          catchError((error) => {
            return of(LoadCountriesFail({ error }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authApiService: AuthApiService,
    private router: Router,
    private store: Store<State>,
    private realTimeService: RealTimeService
  ) {}
}
