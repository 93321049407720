import { Logout } from '@auth/store/actions/auth.actions';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
// TODO: Add ngrx-store-localstorage here. No support for Anuglar 13 yet.
import { localStorageSync } from 'ngrx-store-localstorage';

export interface State {}

export const reducers: ActionReducerMap<State> = {};

export const localStorageSyncData = [
  { auth: ['session', 'profile'] },
  // { appData: ['orders'] },
];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: localStorageSyncData,
    rehydrate: true,
  })(reducer);
}

export function clearState(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State | undefined, action: Action): State => {
    if (action.type === Logout.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer, clearState];
