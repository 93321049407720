import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRootComponent } from '@core/components/app-root/app-root.component';
import { AuthRequiredGuard } from '@auth/guards/auth-required.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'dashboard',
    component: AppRootComponent,
    canActivate: [AuthRequiredGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      {
        path: 'home',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'settings',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/user-settings/user-settings.module').then((m) => m.UserSettingsModule),
      },
      {
        path: 'account',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/user-profile/user-profile.module').then((m) => m.UserProfileModule),
      },
      {
        path: 'orders',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/orders/orders.module').then((m) => m.OrdersTableModule),
      },
      {
        path: 'fulfillment',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/fulfillment/fulfillment.module').then((m) => m.FulfillmentModule),
      },
      {
        path: 'address-book',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/address-book/address-book.module').then((m) => m.AddressBookModule),
      },
      {
        path: 'tracking',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/tracking/tracking.module').then((m) => m.TrackingModule),
      },
      {
        path: 'manifests',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/manifests/manifests.module').then((m) => m.ManifestsModule),
      },
      {
        path: 'statistics',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/statistics/statistics.module').then((m) => m.StatisticsModule),
      },
      {
        path: 'support',
        canActivateChild: [AuthRequiredGuard],
        loadChildren: () => import('./dashboard/support/support.module').then((m) => m.SupportModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes /*{ enableTracing: true }*/, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
