import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

export interface LoginState {
  authenticated: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialState: LoginState = {
  authenticated: false,
  loading: false,
  error: null,
};

export const loginReducer = createReducer(
  initialState,
  on(AuthActions.Login, (state) => ({ ...state, loading: true, error: null })),
  on(AuthActions.LoginSuccess, (state) => ({ ...state, authenticated: true, loading: false, error: null })),
  on(AuthActions.LoginFail, (state, { error }) => ({
    ...state,
    authenticated: false,
    loading: false,
    error,
  })),
  on(AuthActions.Logout, (state) => {
    return initialState;
  })
);
