import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { Profile } from '../../interfaces/profile.interface';

export interface ProfileState {
  userProfile: Profile | null;
  loading: boolean;
  error: Error | null;
}

export const initialState: ProfileState = {
  userProfile: null,
  loading: false,
  error: null,
};

export const profileReducer = createReducer(
  initialState,
  on(AuthActions.LoadProfile, AuthActions.EditProfile, AuthActions.ChangePassword, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AuthActions.LoadProfileSuccess, AuthActions.EditProfileSuccess, (state, { profile }) => ({
    ...state,
    userProfile: profile,
    loading: false,
    error: null,
  })),
  on(AuthActions.ChangePasswordSuccess, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(AuthActions.LoadProfileFail, AuthActions.EditProfileFail, AuthActions.ChangePasswordFail, (state, { error }) => ({
    ...state,
    // userProfile: null,
    loading: false,
    error,
  }))
);
