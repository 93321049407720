import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject, takeUntil } from 'rxjs';
import Backendless from 'backendless';
import { Store } from '@ngrx/store';
import { State } from '@core/store';
import { selectAuthUserUid } from '@auth/store/selectors/session.selectors';

@Injectable({
  providedIn: 'root',
})
export class RealTimeService implements OnDestroy {
  public readonly userId$: Observable<string>;

  private readonly destroyed$ = new Subject<void>();
  private addressesChannel!: Backendless.ChannelClass;
  private selectorAdd!: string;
  private selectorChange!: string;
  private selectorDelete!: string;

  constructor(private store: Store<State>) {
    this.userId$ = this.store.select(selectAuthUserUid);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.addressesChannel.removeAllMessageListeners();
  }

  public initRealTime() {
    const url = environment.api.split('/');
    Backendless.initApp({
      appId: url[3],
      apiKey: url[4],
      serverURL: `https://${url[2]}`,
      debugMode: true,
    });
  }

  public stopRealTime() {
    if (this.addressesChannel) {
      this.addressesChannel.removeAllMessageListeners();
    }
  }

  public initAddressesChannel() {
    this.userId$.pipe(takeUntil(this.destroyed$)).subscribe((userId) => {
      this.addressesChannel = Backendless.Messaging.subscribe('Customers');
      this.selectorAdd = `customerId = '${userId}' AND event = 'customer-address:add'`;
      this.selectorChange = `customerId = '${userId}' AND event = 'customer-address:change'`;
      this.selectorDelete = `customerId = '${userId}' AND event = 'customer-address:delete'`;
      this.addressesChannel.addMessageListener(this.selectorAdd, this.onMessageAdd.bind(this));
      this.addressesChannel.addMessageListener(this.selectorChange, this.onMessageChange.bind(this));
      this.addressesChannel.addMessageListener(this.selectorDelete, this.onMessagDelete.bind(this));
    });
  }

  private onMessageAdd(message: any) {}

  private onMessageChange(message: any) {}

  private onMessagDelete(message: any) {}
}
