import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';

export interface SessionState {
  userRoles: string[];
  userToken: string;
  userId: string;
}

export const initialState: SessionState = {
  userRoles: [],
  userToken: '',
  userId: '',
};

export const sessionReducer = createReducer(
  initialState,
  on(AuthActions.LoginSuccess, (state, { session }) => ({
    ...state,
    userRoles: session.userRoles,
    userToken: session.userToken,
    userId: session.userId,
  })),
  on(AuthActions.UpdateSession, (state, { session }) => ({
    ...state,
    userToken: session.userToken,
    userId: session.userId,
  })),
  on(AuthActions.Logout, (state) => initialState)
);
