import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

interface ResultDialogData {
  message: string;
  status: string;
  orderNumber?: string | number;
  buttons: ('continueBtn' | 'invoicesBtn' | 'historyBtn')[];
  bulkOrdersDetails?: any;
  type: 'bulk' | 'single' | 'address';
}

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultComponent implements OnInit {
  @Input() data!: ResultDialogData;
  @Output() newOrder = new EventEmitter();
  @Output() exportOrders = new EventEmitter();
  public buttonsArray: { label: string; clickFn: string; white: boolean }[] = [];
  @Output() closeSideNav = new EventEmitter();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data && this.data.buttons) {
      this.data.buttons.forEach((element: any) => {
        if (element === 'continueBtn') {
          this.buttonsArray.push({
            label: $localize`Continue`,
            clickFn: 'onContinueBtnClick',
            white: false,
          });
        }
        if (element === 'continueBtnTicket') {
          this.buttonsArray.push({
            label: $localize`Continue`,
            clickFn: 'onContinueBtnTicket',
            white: false,
          });
        } else if (element === 'invoicesBtn') {
          this.buttonsArray.push({
            label: $localize`Invoices`,
            clickFn: 'onInvoicesBtnClick',
            white: true,
          });
        } else if (element === 'historyBtn') {
          this.buttonsArray.push({
            label: $localize`Live Orders`,
            clickFn: 'onHistoryBtnClick',
            white: true,
          });
        }
      });
      // this.cdr.detectChanges();
    }
  }

  public doClick(clickFn: string): void {
    if (clickFn === 'onInvoicesBtnClick') {
      this.router.navigateByUrl('app/invoices', {
        // queryParamsHandling: 'preserve' ,
      });
    } else if (clickFn === 'onHistoryBtnClick') {
      this.router.navigateByUrl('dashboard/orders/live-orders', {
        // queryParamsHandling: 'preserve' ,
      });
    } else if (clickFn === 'onContinueBtnTicket') {
      this.closeSideNav.emit();
      this.cdr.detectChanges();
    } else {
      this.newOrder.emit();
    }
    // this.actions.next(button);
  }

  public exportFailedOrders(type: 'csv' | 'xlsx'): void {
    this.exportOrders.emit(type);
  }
}
