import { createFeatureSelector, createSelector } from '@ngrx/store';
import { loginReducer, LoginState } from './reducer/login.reducer';
import { sessionReducer, SessionState } from './reducer/session.reducer';
import { profileReducer, ProfileState } from './reducer/profile.reducer';
import { countriesReducer, CountriesState } from './reducer/countries.reducer';

export interface State {
  login: LoginState;
  // register: RegisterState;
  session: SessionState;
  profile: ProfileState;
  countries: CountriesState;
}

export const reducers = {
  login: loginReducer,
  // register: registerReducer,
  session: sessionReducer,
  profile: profileReducer,
  countries: countriesReducer,
};

export const selectAuth = createFeatureSelector<State>('auth');
export const selectLoginState = createSelector(selectAuth, (state: State) => state && state.login);
// export const selectRegisterState = createSelector(selectAuth, (state: State) => state && state.register);
export const selectSessionState = createSelector(selectAuth, (state: State) => state && state.session);
export const selectAuthProfileState = createSelector(selectAuth, (state: State) => state && state.profile);
export const selectCountriesState = createSelector(selectAuth, (state: State) => state && state.countries);
